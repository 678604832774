@tailwind base;
@tailwind components;
@tailwind utilities;


.Custom-Font {
  font-family: "Radio Canada", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.oregano-regular {
  font-family: "Oregano", cursive;
  font-weight: 900;
  font-style: normal;
}

.oregano-regular-italic {
  font-family: "Oregano", cursive;
  font-weight: 800;
  font-style: italic;
}

.signature {
  font-family: "Playwrite US Trad", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.signature2 {
  font-family: "Yellowtail", cursive;
  font-weight: 400;
  font-style: normal;
}

.dm-serif-text-regular {
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: normal;
}

.Custom-Font2 {
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: italic;
}



